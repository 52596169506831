<template>
  <div>
    <template v-if="!isMobile">
      <badge compact v-tooltip="error" :type="type" :label="label" />
    </template>
    <div v-else @click.stop="show = !show" class="relative">
      <badge compact :type="type" :label="label" />
      <div class="absolute top-0 left-0 mt-4" v-if="error">
        {{ show ? error : '' }} &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/daisy/atoms/badge/index.vue'

export default {
  name: 'Status',

  components: {
    Badge,
  },

  props: {
    type: {
      type: String,
      default: 'success',
    },
    label: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    show: false,
    isMobile: global.IS_MOBILE,
  }),
}
</script>
