<template>
  <div class="mt-4 flex flex-col">
    <div class="my-2 flex-between w-full" :key="counter">
      <div class="flex-between space-x-2">
        <span class="font-medium">
          {{ $t('Only Manual order') }}
        </span>
        <input
          v-model="onlyManualOrder"
          type="checkbox"
          checked="checked"
          class="toggle border-solid toggle-primary"
        />
      </div>
      <div class="flex-center space-x-2">
        <account-filter @onChange="(selected) => (accountIds = selected)" />
        <logic-filter @onChange="(selected) => (logicIds = selected)" />
        <button class="ml-auto btn-xs-primary" @click.stop="_loadData">
          {{ $t('Apply') }}
        </button>
        <button
          v-if="filtered"
          class="ml-auto btn-xs-warning"
          @click.stop="_reset"
        >
          {{ $t('Reset') }}
        </button>
      </div>
    </div>
    <d-paging
      class="ml-auto hidden lg:block"
      size="xs"
      v-model="page"
      :total="lastPage"
    />
    <div class="overflow-x-scroll">
      <d-table
        class="my-4"
        smallText
        :headers="headers"
        :items="orders"
        compact
        wrapperClasses="min-h-3/5 overflow-y-scroll"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headerGenerator from './headers.js'

export default {
  name: 'AllOrders',

  meta: {
    title: 'All Orders',
  },

  data: () => ({
    isMobile: global.IS_MOBILE,
    accountIds: [],
    logicIds: [],
    filtered: '',
    counter: 1,
    orders: [],
    onlyManualOrder: false,
    page: 1,
    lastPage: 0,
  }),

  computed: {
    ...mapGetters({
      credentials: 'credentials/credentials',
      accountMap: 'credentials/accountMap',
      logicById: 'logic/logicById',
      allLogics: 'logic/logics',
    }),
    headers() {
      return headerGenerator(this)
    },
  },

  watch: {
    page() {
      this._loadData()
    },
    onlyManualOrder() {
      this._reload()
    },
  },

  async mounted() {
    await this._loadData()
  },

  methods: {
    _reload() {
      if (this.page === 1) {
        this._loadData()
      } else {
        this.page = 1
      }
    },
    _reset() {
      this.accountIds = []
      this.logicIds = []
      this._reload()
    },
    async _loadData() {
      const url = '/api/v2/internal-orders/get'
      const { docs, lastPage } = await this.$http.post(url, {
        page: this.page,
        types: this.onlyManualOrder ? [2, 3] : undefined,
        credentials: this.accountIds,
        logics: this.logicIds,
      })
      this.orders = docs
      this.lastPage = lastPage
      this.filtered = this.accountIds.length > 0 || this.logicIds.length > 0
    },
  },
}
</script>
