import { EXCHANGE_NAMES } from '@/common/constants.js'
import Status from './Status.vue'
import Cryptofonts from '@/components/daisy/atoms/cryptofonts/index.vue'
import BadgeOrderSide from '@/components/apps/badges/badge-order-side/index.vue'

import { headerItem } from '@/utils/table'
import { formatNumber, formatDate } from '@/utils/string'

const INTERNAL_ORDER_STATUS = Object.freeze({
  NEW: 1,
  WAITING: 2,
  FILLED: 3,
  CANCELLED: 4,
  FAILED: 5,
})

const { WAITING, FAILED, FILLED } = INTERNAL_ORDER_STATUS

const typeMap = {
  [INTERNAL_ORDER_STATUS.NEW]: 'info',
  [INTERNAL_ORDER_STATUS.WAITING]: 'info',
  [INTERNAL_ORDER_STATUS.FILLED]: 'success',
  [INTERNAL_ORDER_STATUS.CANCELLED]: 'warn',
  [INTERNAL_ORDER_STATUS.FAILED]: 'error',
}
const textMap = {
  [INTERNAL_ORDER_STATUS.NEW]: 'NEW',
  [INTERNAL_ORDER_STATUS.WAITING]: 'WAITING',
  [INTERNAL_ORDER_STATUS.FILLED]: 'FILLED',
  [INTERNAL_ORDER_STATUS.CANCELLED]: 'CANCELLED',
  [INTERNAL_ORDER_STATUS.FAILED]: 'FAILED',
}


export default function headers(_this) {
  // _this
  return [
    global.IS_DEBUG ? headerItem('ID', '_id', {
      generator: (item) => {
        return { value: item._id }
      },
    }) : null,
    headerItem('Account', 'account', {
      cellClass: 'font-semibold uppercase',
      class: 'lg:w-32 w-16 overflow-hidden',
      generator: (item) => {
        return { value: `
          <div>
          ${_this.accountMap[item.credential]?.name || '--'}
          <br/>
          (${EXCHANGE_NAMES[item?.exchange] || '-'})
          </div>
        ` }
      },
    }),
    headerItem('Source', 'source', {
      cellClass: 'font-semibold uppercase',
      class: 'lg:w-48 w-24 overflow-hidden',
      generator: (item) => {

        let value = item.type === 1
          ? _this.logicById[item.logic]?.name || '--'
          : 'Manual order'
        if (item.isClose) {
          value = `(C) ${value}`
        }
        return { value }
      },
    }),
    headerItem('Status', 'status', {
      cellClass: 'w-24',
      generator: (item) => {
        const status = item.status || WAITING
        let error = ''
        if (status === FAILED) {
          error = item.exchangeMessage || 'Unknown error'
        }
        return {
          component: Status,
          props: {
            compact: true,
            error,
            type: typeMap[status] || 'warn',
            label: textMap[status] || 'Unknow',
          },
        }
      },
    }),
    headerItem('Symbol', 'symbol', {
      cellClass: 'font-semibold w-32',
      generator: (item) => {
        if (!item.symbol) {
          return { value: '--' }
        }
        return {
          component: Cryptofonts,
          props: {
            symbol: item.symbol,
          },
        }
      },
    }),
    headerItem('Side', 'side', {
      cellClass: 'font-semibold',
      generator: (order) => ({
        component: BadgeOrderSide,
        props: { order },
      }),
    }),
    headerItem('Price', 'price', {
      cellClass: 'font-semibold',
      generator: (item) => ({
        value: formatNumber(item.price),
      }),
    }),
    headerItem('Volume', 'volume', {
      cellClass: 'font-semibold',
      generator: (item) => ({
        value: formatNumber(item.volume),
      }),
    }),
    headerItem('Order At', 'lastUpdate', {
      cellClass: 'font-semibold',
      generator: (item) => {
        const short = global.IS_MOBILE
        const filled = item.status === FILLED
        return {
          value: `<span>
              ${formatDate(item.createdAt, short)}
              ${filled ? '<br/>' : ''}
              ${filled ? formatDate(item.updatedAt, short) : ''}
            </span>`,
        }
      },
    }),
    headerItem('Order ID', 'orderId', {
      cellClass: 'font-semibold',
      generator: (item) => {
        return { value: item?.exchangeId || '-' }
      },
    }),
  ].filter(Boolean)
}
